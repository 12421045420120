<template>
  <div>
    <el-form
      ref="nodeForm"
      :rules="rules"
      label-position="top"
      label-width="100px"
      :model="nodeToBind"
      :hide-required-asterisk="true"
    >
      <el-form-item prop="node_name" :label="__('Name')">
        <el-input v-model="nodeToBind.node_name"></el-input>
      </el-form-item>

      <!--      <el-form-item-->
      <!--        prop="five9_transfer_node.data.transfer_type"-->
      <!--        label="Transfer type"-->
      <!--      >-->
      <!--        <el-select-->
      <!--          v-model="nodeToBind.five9_transfer_node.data.transfer_type"-->
      <!--          style="width: 100%;"-->
      <!--          placeholder="Please select transfer type"-->
      <!--        >-->
      <!--          <el-option label="Skill" value="skill" />-->
      <!--          <el-option label="Agent" value="agent" />-->
      <!--        </el-select>-->
      <!--      </el-form-item>-->

      <el-form-item
        label="Transfer to endpoint"
        prop="five9_transfer_node.data.property_rules.data.transfer_endpoint"
      >
        <!--        <el-input v-model="transferEndpoint"></el-input>-->
        <el-select
          v-model="transferEndpoint"
          style="width: 100%;"
          default-first-option
          clearable
          :placeholder="__('Select skill')"
          v-loading="skillsLoading"
        >
          <el-option
            v-for="skill in skills"
            :label="skill.skill_label"
            :value="skill.skill_id"
            :key="skill.skill_id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        :label="__('ANI')"
        prop="five9_transfer_node.data.property_rules.data.ani"
      >
        <!-- eslint-disable-next-line -->
        {{ __("Please note: ANI modification feature is network dependent. Please consult your service provider") }}
        <expression-input
          v-model="ani"
          :complex-variables="complexVariables"
          :placeholder="__('Enter ANI using the expression builder')"
          @edit-input="displayAniExpressionBuilderModal = true"
        ></expression-input>
      </el-form-item>
      <el-form-item
        :label="__('Display Name')"
        prop="five9_transfer_node.data.property_rules.data.display_name"
      >
        <expression-input
          v-model="displayName"
          :complex-variables="complexVariables"
          :placeholder="__('Enter display name using the expression builder')"
          @edit-input="displayNameExpressionBuilderModal = true"
        ></expression-input>
      </el-form-item>
      <el-form-item
        :label="__('Transfer timeout (seconds)')"
        prop="five9_transfer_node.data.transfer_timeout"
        v-if="showEventHandler"
      >
        <el-select
          v-model="nodeToBind.five9_transfer_node.data.transfer_timeout"
          style="width: 100%;"
          default-first-option
          clearable
          :placeholder="__('Platform default (optional)')"
        >
          <el-option
            v-for="(item, index) in transferTimeoutLimit"
            :label="item"
            :value="item"
            :key="index"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        :label="__('No answer / busy event handler')"
        prop="five9_transfer_node.data.no_answer_busy_canvas_id"
        v-if="showEventHandler"
      >
        <EventHandlers
          :event-handler-canvas="noAnswerEventHandlerCanvas"
          @update-event-handler="setNoAnswerEventHandlerCanvas($event)"
          event-handler-place-holder="Select  or create a no answer or busy event handler canvas"
          :show-event-handler-count="false"
        ></EventHandlers>
      </el-form-item>
    </el-form>

    <expression-builder-dialog
      v-if="displayAniExpressionBuilderModal"
      v-model="ani"
      :show-expression-builder="displayAniExpressionBuilderModal"
      @input="handleCancelAniExpressionBuilder"
      @cancel="handleCancelAniExpressionBuilder"
      @close="handleCancelAniExpressionBuilder"
    />
    <expression-builder-dialog
      v-if="displayNameExpressionBuilderModal"
      v-model="displayName"
      :show-expression-builder="displayNameExpressionBuilderModal"
      @input="handleCancelDisplayNameExpressionBuilder"
      @cancel="handleCancelDisplayNameExpressionBuilder"
      @close="handleCancelDisplayNameExpressionBuilder"
    />
  </div>
</template>

<script>
import BaseNode from "@/views/build/callflow/components/node-type-forms/BaseNode";
import { NODE_TYPES } from "@/constants/nodes";
import _ from "lodash";

import { mapActions, mapGetters } from "vuex";
import { numberOrVariable } from "@/utils/regex";
import ExpressionInput from "@/views/build/callflow/components/expression-builder/ExpressionInput";
import ExpressionBuilderDialog from "@/views/build/callflow/components/expression-builder/ExpressionBuilderDialog";
import EventHandlers from "@/views/build/callflow/components/node-type-forms/components/EventHandlers";

const initFive9TransferNode = {
  node_type: NODE_TYPES.FIVE9_TRANSFER.NODE_TYPE,
  five9_transfer_node: {
    data: {
      property_rules: {
        data: {
          transfer_endpoint: "",
          display_name: "",
          ani: ""
        }
      },
      transfer_type: "skill",
      transfer_timeout: null,
      no_answer_busy_canvas_id: null
    }
  }
};

export default {
  components: {
    ExpressionBuilderDialog,
    ExpressionInput,
    EventHandlers
  },
  mixins: [BaseNode],

  data() {
    const eventHandlerCanvasInitialize = {
      canvas_name: "",
      canvas_id: -1,
      msg: ""
    };

    // const validateEndpoint = (rule, value, callback) => {
    //   if (!value) {
    //     callback("Transfer endpoint is required");
    //   } else if (_.isEmpty(JSON.parse(value))) {
    //     callback("Transfer endpoint is required");
    //   } else {
    //     callback();
    //   }
    // };
    return {
      rules: {
        five9_transfer_node: {
          data: {
            transfer_type: [
              {
                required: true,
                message: __("Please select transfer type"),
                trigger: "blur"
              }
            ],
            property_rules: {
              data: {
                transfer_endpoint: [
                  {
                    required: true,
                    message: __("Please select transfer type"),
                    trigger: "blur"
                  }
                ]
              }
            }
          }
        }
      },
      variableRulesBkup: [],
      newVariableForAudioURL: 0,
      newVariableForAudioVar: 0,
      allowedRegex: numberOrVariable,
      transferTimeoutLimit: _.range(1, 121),
      displayAniExpressionBuilderModal: false,
      displayNameExpressionBuilderModal: false,
      noAnswerEventHandlerCanvas: _.clone(eventHandlerCanvasInitialize),
      skills: [],
      skillsLoading: false
    };
  },
  computed: {
    ...mapGetters("canvas", {
      getEventHandlerCanvasList: "getEventHandlerCanvasList"
    }),
    currentSelection() {
      return row => {
        console.log("currentSelection ", row);
        const { variable_id, variable_name } = row;
        return variable_id === -1 ? variable_name : variable_id;
      };
    },
    showEventHandler() {
      return (
        !_.isEmpty(JSON.parse(this.ani)) ||
        !_.isEmpty(JSON.parse(this.displayName))
      );
    },

    transferEndpoint: {
      get() {
        return this.nodeToBind.five9_transfer_node.data.property_rules.data
          .transfer_endpoint;
      },
      set(value) {
        this.nodeToBind.five9_transfer_node.data.property_rules.data.transfer_endpoint = value;
      }
    },

    ani: {
      get() {
        return (
          this.nodeToBind.five9_transfer_node.data.property_rules.data.ani ||
          "{}"
        );
      },
      set({ expression }) {
        this.nodeToBind.five9_transfer_node.data.property_rules.data.ani = expression;
      }
    },

    displayName: {
      get() {
        return (
          this.nodeToBind.five9_transfer_node.data.property_rules.data
            .display_name || "{}"
        );
      },
      set({ expression }) {
        this.nodeToBind.five9_transfer_node.data.property_rules.data.display_name = expression;
      }
    }
  },
  created() {
    if (
      !this.nodeToBind.node_id ||
      _.isEmpty(this.nodeToBind.five9_transfer_node)
    ) {
      this.initializeFive9TransferNodeData();
    } else {
      this.fetchDomainSkills();
      if (
        this.nodeToBind.five9_transfer_node.data.no_answer_busy_canvas_id > 0
      ) {
        this.$set(
          this.noAnswerEventHandlerCanvas,
          "canvas_id",
          this.nodeToBind.five9_transfer_node.data.no_answer_busy_canvas_id
        );
      }
    }
  },
  methods: {
    ...mapActions("accounts", {
      getDomainSkills: "getDomainSkills"
    }),
    async fetchDomainSkills() {
      this.skillsLoading = true;
      await this.getDomainSkills()
        .then(data => {
          this.skills = [];
          _.map(data.data, skill => {
            let skillInfo = {
              skill_id: skill.skill_id,
              skill_name: skill.skill_name,
              skill_label: `${skill.skill_name} - ${skill.skill_id}`
            };
            this.skills.push(skillInfo);
          });
        })
        .catch(err => {
          this.$message({
            type: "error",
            message: err.message
          });
        });
      this.skillsLoading = false;
    },
    initializeFive9TransferNodeData() {
      this.nodeToBind = Object.assign(
        {},
        this.nodeToBind,
        _.cloneDeep(initFive9TransferNode)
      );
      this.fetchDomainSkills();
    },
    removeTranferTimeoutAndEventHandler() {
      this.nodeToBind.five9_transfer_node.data.no_answer_busy_canvas_id = null;
      this.nodeToBind.five9_transfer_node.data.transfer_timeout = null;
    },

    handleCancelAniExpressionBuilder() {
      this.displayAniExpressionBuilderModal = false;
    },
    handleCancelDisplayNameExpressionBuilder() {
      this.displayNameExpressionBuilderModal = false;
    },
    setNoAnswerEventHandlerCanvas() {
      this.$set(
        this.nodeToBind.five9_transfer_node.data,
        "no_answer_busy_canvas_id",
        this.noAnswerEventHandlerCanvas
      );
    },

    cleanUpFive9TransferSettings() {
      if (!this.showEventHandler) {
        this.removeTranferTimeoutAndEventHandler();
      } else {
        if (
          !_.isEmpty(
            this.nodeToBind.five9_transfer_node.data.no_answer_busy_canvas_id,
            "canvas_name"
          ) &&
          this.nodeToBind.five9_transfer_node.data.no_answer_busy_canvas_id
            .canvas_name === ""
        ) {
          this.nodeToBind.five9_transfer_node.data.no_answer_busy_canvas_id = null;
        }
      }
    },

    cleanUpNodeToPrepareForSubmit() {
      // do necessary operations on a cloned version of nodeToBind obj
      // that return the nodeToBind object
      // in exactly the same way as an node object of this
      // type is returned from the backend

      // as I do not require any cleanup to do here in this particular case,
      // I am just sending back a cloned version of nodeToBind obj
      return _.cloneDeep(this.nodeToBind);
    },
    cleanUpNode() {
      // the manipulation to clean up the node
      // is moved to the above method. So we can
      // reassign the nodeToBind object as the object
      // that is returned from the cleanUpNodeToPrepareForSubmit method

      this.cleanUpFive9TransferSettings();
      this.nodeToBind = this.cleanUpNodeToPrepareForSubmit();
      this.createOrEditNode();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/element-variables.scss";
@import "~@/styles/node_common.scss";

.audio-player {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
::v-deep .row-message {
  font-size: 0.75rem;
  color: $--color-success;
  margin-left: 10px;
}
.promptEditor {
  ::v-deep .editableContent {
    height: 200px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid #a0a8b5;
    outline: none;
    padding: 10px;
    overflow: auto;
    &:focus {
      border: 1px solid black;
    }
  }
}
</style>
