<template>
  <div>
    <el-row type="flex">
      <el-col :span="dynamicSpan">
        <el-form-item :label="eventHandlerLabel" :class="cssClass">
          <create-or-select-canvas
            :event-handler-canvas="eventHandlerCanvas"
            :place-holder="eventHandlerPlaceHolder"
            @change="updateEventHandlerCanvas($event)"
          ></create-or-select-canvas>
        </el-form-item>
      </el-col>
      <el-col :span="4" :offset="1" v-if="showEventHandlerCount">
        <el-form-item prop="no_input_count" :label="__('Count')">
          <el-input-number
            v-model="eventHandlerCount"
            :min="0"
            :step="1"
            :max="9"
            :step-strictly="true"
            controls-position="right"
            size="small"
            @change="updateEventHandlerCount"
          ></el-input-number>
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import CreateOrSelectCanvas from "@/views/build/callflow/components/node-type-forms/components/CreateOrSelectCanvas";
export default {
  name: "EventHandlers",
  components: {
    CreateOrSelectCanvas
  },
  props: {
    eventHandlerCanvas: {
      type: [Object, Number, null]
    },
    count: {
      required: false,
      type: Number
    },
    eventHandlerLabel: {
      type: String,
      required: false
    },
    eventHandlerPlaceHolder: {
      type: String,
      required: false,
      default: __("Select or create a no input event handler canvas")
    },
    showEventHandlerCount: {
      type: Boolean,
      default: true
    },
    cssClass: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      eventHandlerCount: this.count
    };
  },
  computed: {
    dynamicSpan() {
      return this.showEventHandlerCount ? 23 : 24;
    }
  },
  methods: {
    updateEventHandlerCanvas(value) {
      this.$emit("update-event-handler", value);
    },
    updateEventHandlerCount(value) {
      this.$emit("update-event-handler-count", value);
    }
  }
};
</script>

<style scoped></style>
